import { Typography, Link as MuiLink } from "@mui/material";
import React from "react";


export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <MuiLink color="inherit" href="https://pabs.dev/">
        pabs.dev
      </MuiLink>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
